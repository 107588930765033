define("discourse/plugins/fee-calculator/discourse/templates/components/fc-section", ["exports", "ember-this-fallback/is-component", "ember-this-fallback/this-fallback-helper", "ember-this-fallback/try-lookup-helper", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _isComponent, _thisFallbackHelper, _tryLookupHelper, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="fc-section-help">
    <div class="fc-section-num">
      <span>{{num}}</span>
    </div>
  
    <div class="fc-section-msg">
      {{html-safe msg}}
    </div>
  </div>
  
  <div class="fc-section-input">
    {{yield}}
  </div>
  
  */
  {
    "id": "ksFsdJwF",
    "block": "[[[10,0],[14,0,\"fc-section-help\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"fc-section-num\"],[12],[1,\"\\n    \"],[10,1],[12],[41,[28,[32,0],[\"num\"],null],[[[8,[39,1],null,null,null]],[]],[[[44,[[28,[37,3],null,[[\"num\"],[[28,[32,1],[\"num\"],null]]]]],[[[1,[52,[30,1,[\"num\"]],[28,[30,1,[\"num\"]],null,null],[28,[32,2],[[30,0],\"num\",\"[\\\"The `num` property path was used in the `discourse/plugins/fee-calculator/discourse/templates/components/fc-section.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.num}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]\"],null]]]],[1]]]],[]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"fc-section-msg\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[[30,0,[\"msg\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"fc-section-input\"],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"],[1,[28,[32,3],[\"[[\\\"The `msg` property path was used in the `discourse/plugins/fee-calculator/discourse/templates/components/fc-section.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.msg}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[\"maybeHelpers\",\"&default\"],false,[\"if\",\"num\",\"let\",\"hash\",\"html-safe\",\"yield\"]]",
    "moduleName": "discourse/plugins/fee-calculator/discourse/templates/components/fc-section.hbs",
    "scope": () => [_isComponent.default, _tryLookupHelper.default, _thisFallbackHelper.default, _deprecationsHelper.default],
    "isStrictMode": false
  });
});