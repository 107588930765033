define("discourse/plugins/fee-calculator/discourse/routes/fees", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    titleToken() {
      return I18n.t("fee_calculator.title");
    }
  });
});